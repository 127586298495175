import {
  Visibility,
  VisibilityOff
} from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AboutParaTypo,
  FilledTextField,
  LoginHeader
} from "./styledcomponents/beforeLoginWidgets";
import {
  StyledGreenButton
} from "./styledcomponents/styledDBwidgets";
import * as yup from "yup";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { pgNeetUser, pgNeetUserAuth, signUpApi } from "../http/allApis";
import { useFormik } from "formik";
import { profileCheck } from "./landing/profileCheck";
import GoogleLoginButton from "./student/widgets/GoogleLoginButton";
import FBLoginButton from "./student/widgets/FBLoginButton";

// const provider = new firebase.auth.GoogleAuthProvider();
const firebaseConfig = {
  apiKey: "AIzaSyC1Y63L9soB_oo7OFZJQHmG0-TtglAvWZo",
  authDomain: "pgneet-jola.firebaseapp.com",
  projectId: "pgneet-jola",
  storageBucket: "pgneet-jola.appspot.com",
  messagingSenderId: "349365337208",
  appId: "1:349365337208:web:b8ce87e95289e7cc79418d"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
// provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
// auth.languageCode = "it";
function SignUp({ handleToggle }) {
  const navigate = useNavigate();
  const [startLoader, setStartLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [alertstatus, setAlertstatus] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  let inputRef = null;
  const handleKeyUp = (e, target) => {
    if (e.keyCode === 13) {
      switch (target) {
        case "email":
          document.getElementById("password").focus();
          break;
        case "password":
          document.getElementById("login").focus();
        default:
          break;
      }
    }
  };

  const openNewTab = (url) => {
    window.open(url + "terms");
  };

  const handleSocialSignUp = (userVal) => {
    console.log(userVal);
    handleAfterSignup(userVal);
  };

  const handleAfterSignup = (userVal) => {
    if (userVal?.accessToken) {
      pgNeetUser(userVal)
        .then((res1) => {
          sessionStorage.setItem(
            "person",
            JSON.stringify(res1?.data?.result[0])
          );
          sessionStorage.setItem("usertokenType", userVal?.tokenType);
          if (userVal?.userRole === "Student") {
            if (profileCheck(res1?.data?.result[0])) {
              navigate("/user/dashboard");
              setStartLoader(false);
            } else {
              navigate("/user/profile");
            }
          } else {
            navigate("/admin/dashboard");
          }
        })
        .catch((err) => {});
    } else {
      pgNeetUserAuth(userVal?.id)
        .then((res1) => {
          sessionStorage.setItem("person", JSON.stringify(res1?.data?.data[0]));
          if (userVal?.userRole === "Student") {
            if (profileCheck(res1?.data?.data[0])) {
              navigate("/user/dashboard");
              setStartLoader(false);
            } else {
              navigate("/user/profile");
            }
          } else {
            navigate("/admin/dashboard");
          }
        })
        .catch((err) => {});
    }
  };

  const handleAlert = (msg, icon) => {
    setMessage(msg);
    setAlertstatus(icon);
    setAlertOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
      // mobile: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Enter a valid Email Address "
        )
        .strict()
        .trim()
        .required("Email is required"),
      password: yup
        .string()
        .strict()
        .trim("Empty Space not allowed")
        .required("Password required")
    }),

    onSubmit: () => {
      sessionStorage.clear();
      setStartLoader(true);
      var logindata = {
        password: formik.values.password,
        email: formik.values.email
      };
      signUpApi(logindata)
        .then((res) => {
          setStartLoader(false);
          if (res.data.status === "Success") {
            sessionStorage.setItem("token", res.data.data);
            handleAfterSignup(res.data.data);
          } else {
            setMessage(res.data.error.description);
            setAlertstatus("warning");
            setAlertOpen(true);
            return Promise.reject(res.data.error.description);
          }
        })
        .catch((err) => {});
    }
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (alertOpen) {
      setAlertOpen(false);
    }
  }, [formik.values.email, formik.values.password]);

  return (
    <div>
      <Backdrop open={startLoader} />
      <LoginHeader variant="h5" align="center" fontWeight={"bold"}>
        SIGN UP
      </LoginHeader>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        {alertOpen ? (
          <Alert
            onClose={() => setAlertOpen(false)}
            severity={alertstatus}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        ) : null}
        <div style={{ height: "6rem" }}>
          <FilledTextField
            label="Email address"
            type={"email"}
            id="email"
            variant="filled"
            style={{ marginTop: "1rem" }}
            fullWidth
            ref={(input) => (inputRef = input)}
            onKeyUp={(e) => {
              handleKeyUp(e, (inputRef = e.target.name));
            }}
            name="email"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
          />
          <Typography
            variant="caption"
            color="error"
            style={{ marginLeft: ".5rem" }}
          >
            {formik.touched.email && formik.errors.email
              ? formik.errors.email
              : " "}
          </Typography>
        </div>
        <div style={{ height: "6rem" }}>
          <FilledTextField
            id="name"
            variant="filled"
            style={{ marginTop: "1rem" }}
            fullWidth
            onKeyUp={(e) => {
              handleKeyUp(e, (inputRef = e.target.name));
            }}
            name="password"
            value={formik.values.password}
            label="Password"
            error={formik.touched.password && formik.errors.password}
            type={showPassword ? "text" : "password"}
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    style={{ outline: "none" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {formik.touched.password && formik.errors.password ? (
            <Typography
              variant="caption"
              color="error"
              style={{ marginLeft: ".5rem" }}
            >
              {formik.errors.password}
            </Typography>
          ) : null}
        </div>
        <div style={{ marginTop: "1rem" }}>
          <AboutParaTypo variant="body2">
            <Checkbox
              size="small"
              onChange={() => setAcceptTerms(!acceptTerms)}
              sx={{
                color: "gray",
                marginRight: ".5rem",
                padding: 0,
                "&.Mui-checked": {
                  color: "#31a188"
                }
              }}
              required
              value={acceptTerms}
              checked={acceptTerms}
            />
            I accept
            <span
              style={{
                color: "#31a188",
                cursor: "pointer",
                textDecoration: "none"
              }}
              variant="body2"
              onClick={() => openNewTab("https://www.pgneet.net/")}
            >
              {" "}
              Terms and Conditions
            </span>
          </AboutParaTypo>
        </div>
        <div
          style={{
            marginTop: "2rem"
          }}
        >
          <StyledGreenButton
            disabled={!(formik.isValid && formik.dirty)}
            type="submit"
            style={{ width: "100%" }}
          >
            {startLoader ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              "Register"
            )}
          </StyledGreenButton>
        </div>
        <div
          style={{
            marginTop: "1rem",
            position: "relative",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              content: '""',
              display: "block",
              width: "160px",
              height: "1px",
              background: "gray",
              position: "absolute",
              top: "50%",
              left: 0
            }}
          ></div>
          OR
          <div
            style={{
              content: '""',
              display: "block",
              width: "160px",
              height: "1px",
              background: "gray",
              position: "absolute",
              top: "50%",
              right: 0
            }}
          ></div>
        </div>
        <div
          style={{
            marginTop: ".7rem",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FBLoginButton
              buttonFor={"signup"}
              callBackFunction={handleSocialSignUp}
              handleAlert={handleAlert}
            />
          </div>
          <GoogleLoginButton
            buttonFor={"signup"}
            callBackFunction={handleSocialSignUp}
            handleAlert={handleAlert}
          />
        </div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <AboutParaTypo variant="body2">
            Already have User ?
            <span
              onClick={() => handleToggle("login")}
              style={{
                color: "#31a188",
                cursor: "pointer",
                textDecoration: "underline"
              }}
            >
              {" "}
              LOGIN
            </span>
          </AboutParaTypo>
        </div>
      </form>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SignUp;
