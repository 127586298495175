import {
  TransparentHead,
  AboutParaTypo,
  AboutParaTypoDiv
} from "../../../styledcomponents/beforeLoginWidgets";
import AdminTable from "../../../admin/widgets/AdminTable";
import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
  InputBase
} from "@mui/material";
import { ArrowDropDown, Add } from "@mui/icons-material";
import { StyledGreenButton } from "../../../styledcomponents/styledDBwidgets";
import { admimMockAnswerApi } from "../../../../http/allAdminApi";
import AdminTextEditor from "../../widgets/AdminTextEditor";
import { Form, Modal, Switch } from "antd";
import { Navigate, useNavigate } from "react-router";
import {
  MockAddApi,
  MockDeleteApi,
  MockEditApi,
  MockRefApi,
  SettingSubjectApi,
  mockQuestionApi
} from "../../../../http/allAdminApi";
import { default as swal } from "sweetalert2";
import AdminDataGrid from "../../widgets/AdminDataGrid";
import ModelFile from "../../../landing/ModelFile";

function AdminMockQuestion() {
  const [checkBox, setCheckBox] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionId, setActionId] = useState("");
  const [subCatOptions, setSubCatOptions] = useState([]);
  const navigate = useNavigate();
  const [subCatVal, setSubCatVal] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [explanationText, setExplanationText] = useState("");
  const [referenceText, setReferenceText] = useState("");
  const qbOpen = Boolean(anchorEl);
  const [showPage, setShowPage] = useState("Table");
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [alertstatus, setAlertstatus] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [paramData, setParamData] = useState();
  const [rowsData, setRowsData] = useState([]);
  const [catchError, setCatchError] = useState("");
  const [modelWin, setModelWin] = useState(false);
  const [modelMockQB, setModelMockQB] = useState(false);
  const handleApiClose = () => {
    setOpen(false);
  };
  const columns = [
    { field: "id", headerName: "ID", flex: 0.6, hide: true },
    {
      field: "question",
      headerName: "Question",
      flex: 6,
      renderCell: (params) => {
        return (
          <div
            style={{
              whiteSpace: "pre-wrap",
              width: "100%",
              overflow: "auto",
              padding: "1rem"
            }}
          >
            <AboutParaTypo variant="p">
              <div
                style={{
                  width: "100%"
                }}
                dangerouslySetInnerHTML={{
                  __html: params?.row?.question
                }}
              />
            </AboutParaTypo>
          </div>
        );
      }
    },
    {
      field: "mainCategory",
      headerName: "Subject",
      flex: 2,
      hide: true
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      hide: true,
    },
    {
      field: "0",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <AboutParaTypoDiv
            style={{
              backgroundColor: params.row.status ? "green" : "red"
            }}
          >
            <AboutParaTypo
              variant="caption"
              align="center"
              style={{ color: "#fff" }}
            >
              {params.row.status ? "Active" : "Inactive"}
            </AboutParaTypo>
          </AboutParaTypoDiv>
        );
      }
    },
    {
      field: "1",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              size="small"
              style={{
                backgroundColor: "#6C757D",
                color: "#fff",
                textTransform: "none",
                outline: "none"
              }}
              onClick={(e) => {
                setParamData(params.row);
                setAnchorEl(e.currentTarget);
              }}
              id={"demo-positioned-button" + params.row.id}
            >
              Action <ArrowDropDown style={{ color: "#fff" }} />
            </Button>
          </div>
        );
      }
    }
  ];

  const handleAnswer = (data) => {
    let mockVal = { ...data };
    mockVal.answer = [];
    admimMockAnswerApi(paramData?.id).then((res) => {
      if (res.data.status === "Success") {
        mockVal.answer = res?.data?.data || [];
        navigate("/admin/mock/answers/" + paramData?.id, {
          state: {
            questionData: mockVal
          }
        });
      }
    });
  };
  const handleMainCategory = () => {
    SettingSubjectApi().then((res) => {
      if (res?.data?.status === "Success") {
        let data = res?.data?.data;
        setSubCatOptions(data);
      }
    });
  };

  const handleMockMenu = (val) => {
    setAnchorEl(null);
    if (val === "Add") {
      setShowPage(val);
      handleMainCategory();
      setModelMockQB(true);
    } else if (val === "Edit") {
      setShowPage(val);
      handleMainCategory();
      setModelMockQB(true);

      MockRefApi(paramData.id).then((res) => {
        if (res?.data?.status === "Success") {
          let data = res?.data?.data;
          setSubCatVal(data?.subjectId);
          setQuestionText(data?.question);
          setReferenceText(data?.myReference);
          setExplanationText(data?.explanation);
          setCheckBox(data?.status);
        }
      });
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value.trim().toLowerCase();
    const filteredResults = rowsData?.filter((item) => {
      const searchText = value.replace(/\s/g, "");
      const fieldsToSearch = [
        item.id?.toString() || "",
        item.mainCategory || "",
        item.question || "",
        item.image.toString() || ""
      ].map((field) => field.replace(/\s/g, "").toLowerCase());

      return fieldsToSearch.some((field) => field.includes(searchText));
    });
    setSearchVal(value);
    setResData(filteredResults);
  };

  const handleMockSave = () => {
    if (showPage === "Add") {
      handleMockAdd();
    } else if (showPage === "Edit") {
      handleMockEdit();
    }
  };

  const handleSuccess = (res) => {
    if (res?.data?.status === "Success") {
      setSuccessMsg(res?.data?.message);
      setAlertstatus("success");
      setOpen(true);
      handleMockQues();
      setAnchorEl(null);
      setShowPage("Table");
      setReferenceText("");
      setExplanationText("");
      setQuestionText("");
      setSubCatVal("");
      setModelMockQB(false);
    } else {
      setSuccessMsg(res?.data?.error?.description);
      setAlertstatus("warning");
      setOpen(true);
    }
  };

  const handleMockAdd = () => {
    let payload = {
      mainCategory: subCatVal,
      questionExplain: explanationText,
      questionRef: referenceText,
      question: questionText,
      status: checkBox
    };
    MockAddApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleMockEdit = () => {
    let payload = {
      id: paramData.id,
      mainCategory: subCatVal,
      question: questionText,
      questionExplain: explanationText,
      questionRef: referenceText,
      status: checkBox
    };
    MockEditApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const deleteApi = (id) => {
    let payload = {
      id: id
    };
    MockDeleteApi(payload)
      .then((res) => {
        handleSuccess(res);
      })
      .catch((e) => {
        setSuccessMsg("Error!");
        setAlertstatus("warning");
        setOpen(true);
        console.log(e);
      });
  };

  const handleDelete = () => {
    setAnchorEl(null);
    swal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then((willDelete) => {
        if (willDelete.isConfirmed) {
          deleteApi(paramData.id);
        }
      });
  };

  const handleMockQues = () => {
    setLoading(true);
    mockQuestionApi()
      .then((res) => {
        if (res?.data?.status === "Success") {
          setResData(res?.data?.data);
          setRowsData(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((response) => {
        if (response?.response?.data?.status === 500) {
          setModelWin(true);
          setCatchError(response?.response?.data?.message);
        } else {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    handleMockQues();
  }, []);

  const handleClose = () => {
    setShowPage("Table");
    setReferenceText("");
    setExplanationText("");
    setQuestionText("");
    setSubCatVal("");
    setModelMockQB(false);
  };

  return (
    <div>
      <Backdrop open={loading}>
        <CircularProgress style={{ color: "#f38424" }} />
      </Backdrop>
      <TransparentHead
        variant="h5"
        style={{ color: "#2c3643", fontWeight: "bold" }}
      >
        Mock Question
      </TransparentHead>
      <div
        style={{
          margin: "1rem 0",
          backgroundColor: "#fff",
          padding: "2rem 1rem"
        }}
      >
        <div style={{ width: "100%" }}>
          <Modal
            width={1350}
            open={modelMockQB}
            onOk={() => setModelMockQB(false)}
            onCancel={handleClose}
            footer={null}
          >
            {/* {showPage === "Add" || showPage === "Edit" ? ( */}
            <div>
              <TransparentHead
                variant="h6"
                style={{ color: "#2c3643", fontWeight: "bold" }}
              >
                Subject
              </TransparentHead>
              <div style={{ marginTop: "1rem" }}>
                <TextField
                  select
                  size="small"
                  value={subCatVal}
                  id="name"
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      style: { maxHeight: 300 }
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      border: "1px solid #000",
                      padding: "0.5rem 0.3rem 0.5rem 0.2rem",
                      color: "#000"
                    },
                    "& .MuiInputLabel-root": {
                      color: "#000"
                    },
                    "& .MuiInputLabel-formControl": {
                      top: "-8px"
                    }
                  }}
                  onChange={(e) => setSubCatVal(e.target.value)}
                >
                  <MenuItem value={""} />
                  {subCatOptions?.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      {item.subject}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <TransparentHead
                variant="h6"
                style={{
                  color: "#2c3643",
                  fontWeight: "bold",
                  marginTop: "1rem"
                }}
              >
                Question
              </TransparentHead>
              <div
                style={{
                  lineHeight: "1em",
                  color: "#000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem",
                  marginTop: "1rem"
                }}
              >
                <AdminTextEditor
                  editorFor={"QBQuestion"}
                  content={questionText}
                  setContent={(val) => {
                    setQuestionText(val);
                  }}
                />
              </div>
              <TransparentHead
                variant="h6"
                style={{
                  color: "#2c3643",
                  fontWeight: "bold",
                  marginTop: "1rem"
                }}
              >
                Explanation
              </TransparentHead>
              <div
                style={{
                  lineHeight: "1em",
                  color: "#000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem",
                  marginTop: "1rem"
                }}
              >
                <AdminTextEditor
                  editorFor={"QBExplanation"}
                  content={explanationText}
                  setContent={(val) => setExplanationText(val)}
                />
              </div>
              <TransparentHead
                variant="h6"
                style={{
                  color: "#2c3643",
                  fontWeight: "bold",
                  marginTop: "1rem"
                }}
              >
                Reference
              </TransparentHead>
              <div
                style={{
                  lineHeight: "1em",
                  color: "#000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem",
                  marginTop: "1rem"
                }}
              >
                <AdminTextEditor
                  editorFor={"QBReference"}
                  content={referenceText}
                  setContent={(val) => setReferenceText(val)}
                />
              </div>
              <div>
                <Form.Item label="Status">
                  <Switch
                    checked={checkBox}
                    onChange={() => setCheckBox(!checkBox)}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: "#dc3545",
                    color: "#fff",
                    marginRight: "1rem",
                    outline: "none"
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor:
                      referenceText === "" ||
                      explanationText === "" ||
                      questionText === "" ||
                      subCatVal === ""
                        ? "white"
                        : "#2c3643",
                    color:
                      referenceText === "" ||
                      explanationText === "" ||
                      questionText === "" ||
                      subCatVal === ""
                        ? "black"
                        : "#fff",
                    outline: "none"
                  }}
                  disabled={
                    referenceText === "" ||
                    explanationText === "" ||
                    questionText === "" ||
                    subCatVal === ""
                  }
                  onClick={handleMockSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        {/* ) : ( */}
        <div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <StyledGreenButton
              onClick={() => handleMockMenu("Add")}
              style={{ borderRadius: "50px", width: "8rem", outline: "none" }}
            >
              <Add fontSize="small" /> Add New
            </StyledGreenButton>
          </div>
          <div style={{ marginTop: "1rem" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "1rem",
                color: "black"
              }}
            >
              <InputBase
                placeholder="Search..."
                value={searchVal}
                size="small"
                style={{
                  width: "35rem",
                  lineHeight: "1em",
                  color: "#000",
                  border: "1px solid #000",
                  margin: ".4rem 0 .5rem 0",
                  padding: ".3rem 0 .3rem .2rem"
                }}
                onChange={handleInputChange}
              />
            </div>
            <AdminDataGrid loading={loading} columns={columns} rows={resData} />
          </div>
        </div>
        {/* )} */}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleApiClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleApiClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {successMsg}
        </Alert>
      </Snackbar>
      <Menu
        id={"demo-positioned-menu" + paramData?.id}
        aria-labelledby={"demo-positioned-button" + paramData?.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        PaperProps={{
          style: {
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <MenuItem id={"Answers"} onClick={() => handleAnswer(paramData)}>
          Answers
        </MenuItem>
        <MenuItem onClick={() => handleMockMenu("Edit", paramData)}>
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleDelete("Delete", paramData)}>
          Delete
        </MenuItem>
      </Menu>
      <div>
        <ModelFile modelWin={modelWin} catchError={catchError} />
      </div>
    </div>
  );
}

export default AdminMockQuestion;
