import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import DashboardComp from "./components/dashboard/DashboardComp";
import HighYieldPage from "./components/dashboard/HighYieldPage";
import AboutPage from "./components/landing/AboutPage";
import HomePage from "./components/landing/HomePage";
import LandingMenu from "./components/landing/LandingMenu";
import LandingPage from "./components/landing/LandingPage";
import LoginSignUp from "./components/landing/LoginSignUp";
import MockTestHome from "./components/landing/MockTestHome";
import QuestionBankHome from "./components/landing/QuestionBankHome";
import Terms from "./components/landing/Terms";
import ContactAuth from "./components/student/ContactAuth";
import PerformancePage from "./components/student/performance/PerformancePage";
import Invoices from "./components/student/profile/Invoices";
import Profile from "./components/student/profile/Profile";
import QbExam from "./components/student/QbExam";
import SessionReview from "./components/student/quiz/SessionReview";
import QuizPage from "./components/student/QuizPage";
import ResetQuestionsHist from "./components/student/resetquestion/ResetQuestionsHist";
import MockTestPage from "./components/student/mocktest/MockTestPage";
import PrivacyPolicy from "./components/landing/PrivacyPolicy";
import CopyRights from "./components/landing/CopyRights";
import AdminQBAnswers from "./components/admin/pages/AdminQBAnswers";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLanding from "./components/admin/AdminLanding";
import AdminQuestion from "./components/admin/pages/AdminQuestion";
import AdminTextBook from "./components/admin/pages/AdminTextBook";
import AdminImportCSV from "./components/admin/pages/AdminImportCSV";
import AdminHighYieldPage from "./components/admin/pages/AdminHighYieldPage";
import AdminHYTopic from "./components/admin/pages/AdminHYTopic";
import AdminHYCategory from "./components/admin/pages/AdminHYCategory";
import AdminMockPage from "./components/admin/pages/mock/AdminMockPage";
import AdminMockQuestion from "./components/admin/pages/mock/AdminMockQuestion";
import AdminMockTextBook from "./components/admin/pages/mock/AdminMockTextBook";
import AdminFAQ from "./components/admin/pages/AdminFAQ";
import AdminInvoice from "./components/admin/pages/AdminInvoice";
import AdminMembers from "./components/admin/pages/AdminMembers";
import AdminMainCatPage from "./components/admin/pages/AdminMainCatPage";
import AdminCategory from "./components/admin/pages/AdminCategory";
import AdminMainCategory from "./components/admin/pages/AdminMainCategory";
import AdminSubCategory from "./components/admin/pages/AdminSubCategory";
import AdminState from "./components/admin/pages/AdminState";
import AdminExam from "./components/admin/pages/AdminExam";
import AdminChangePassword from "./components/admin/pages/AdminChangePassword";
import AdminSubscription from "./components/admin/pages/AdminSubscription";
import AdminHYViewTopics from "./components/admin/pages/AdminHYViewTopics";
import AdminMockQBAnswers from "./components/admin/pages/AdminMockQBAnswers";
import VeritfyEmail from "./http/VeritfyEmail";
import React from "react";
import AdminQuesSetLimit from "./components/admin/pages/AdminQuesSetLimit";
import { Watermark } from "antd";
import AdminMenu from "./components/admin/widgets/AdminMenu";
import FaqsPage from "./components/landing/FaqsPage";
import Blog from "./components/landing/Blog";
import AdminBlogs from "./components/admin/pages/AdminBlogs";
import AdminBlogsHeading from "./components/admin/pages/AdminBlogsHeading";
import { AdminInstruction } from "./components/admin/pages/AdminInstruction";
import ArticleFile from "./components/landing/ArticleFile";
import AdminRoles from "./components/admin/pages/AdminRoles";
import VeritfyBlogEmail from "./http/VeritfyBlogEmail";
import BlogUnsubscription from "./http/BlogUnsubscription";
import { SharedValueProvider } from "./components/Common/SharedValueContext";
import { VeritfyComments } from "./http/VeritfyComments";
function App() {
  return (
    <div>
      <SharedValueProvider>
        <Watermark
          content="Test Mode"
          height={32}
          width={165}
          font={{ fontSize: 21 }}
        >
          <Routes>
            <Route path="/" element={<LandingPage />}>
              <Route path="/" element={<HomePage />} />
              <Route path="courses" element={<HomePage />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="faqs" element={<FaqsPage />} />
              <Route path="article" element={<ArticleFile />} />
              <Route path="blog" element={<Blog />} />
              <Route path="blog/welcome" element={<Blog />} />
              <Route path="blog/article/:id" element={<Blog />} />
              <Route path="contact" element={<AboutPage />} />
              <Route path="login" element={<LoginSignUp />} />
              <Route path="terms" element={<Terms />} />
              <Route path="copy-rights" element={<CopyRights />} />
              <Route
                path="reset-password"
                element={<LoginSignUp reset={true} />}
              />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="menu" element={<LandingMenu />} />
              <Route path="questionbank" element={<QuestionBankHome />} />
              <Route
                path="courses/questionbank"
                element={<QuestionBankHome />}
              />
              <Route path="courses/mocktest" element={<MockTestHome />} />
              <Route path="mocktest" element={<MockTestHome />} />
            </Route>
            <Route path="/user" element={<Dashboard />}>
              <Route path="dashboard" element={<DashboardComp />} />
              <Route path="menu" element={<LandingMenu type={"afterAuth"} />} />
              <Route path="profile" element={<Profile />} />
            </Route>
            <Route path="/student" element={<Dashboard />}>
              <Route path="mocktest" element={<MockTestPage />} />
              <Route path="qbexam" element={<QbExam />} />
              <Route path="quiz" element={<QuizPage type={"quiz"} />} />
              <Route
                path="timed-test"
                element={<QuizPage type={"timed-test"} />}
              />
              <Route path="mock-test" element={<QuizPage type={"mock"} />} />
              <Route path="review" element={<SessionReview />} />
              <Route path="highyield" element={<HighYieldPage />} />
              <Route
                path="highyield/:categoryParam"
                element={<HighYieldPage />}
              />
              <Route path="performance" element={<PerformancePage />} />
              <Route path="resetqb" element={<ResetQuestionsHist />} />
              <Route path="contact" element={<ContactAuth />} />
              <Route path="myinvoices" element={<Invoices />} />
            </Route>
            <Route path="/admin" element={<AdminDashboard />}>
              <Route path="menu" element={<AdminMenu />} />
              <Route path="dashboard" element={<AdminLanding />} />
              <Route path="question" element={<AdminQuestion />} />
              <Route path="textbook" element={<AdminTextBook />} />
              <Route
                path="answers/:answerParams"
                element={<AdminQBAnswers />}
              />
              <Route
                path="importcsv"
                element={<AdminImportCSV type={"qb"} />}
              />
              <Route path="instruction" element={<AdminInstruction />} />

              <Route
                path="qbsetlimit"
                element={<AdminQuesSetLimit type={"qb"} />}
              />
              <Route
                path="mockquestionsetlimit"
                element={<AdminQuesSetLimit type={"mock"} />}
              />
              <Route path="blogsheading" element={<AdminBlogsHeading />} />
              <Route path="createblogs" element={<AdminBlogs />} />

              <Route path="highyield" element={<AdminHighYieldPage />}>
                <Route path="topic" element={<AdminHYTopic />} />
                <Route path="category" element={<AdminHYCategory />} />
                <Route path="viewtopic" element={<AdminHYViewTopics />} />
              </Route>
              <Route path="mock" element={<AdminMockPage />}>
                <Route path="question" element={<AdminMockQuestion />} />
                <Route
                  path="answers/:answerParams"
                  element={<AdminMockQBAnswers />}
                />
                <Route path="textbook" element={<AdminMockTextBook />} />
                <Route
                  path="importcsv"
                  element={<AdminImportCSV type={"mock"} />}
                />
              </Route>
              <Route path="faq" element={<AdminFAQ />} />
              <Route path="invoice" element={<AdminInvoice />} />
              <Route path="members" element={<AdminMembers />} />
              <Route path="roles" element={<AdminRoles />} />
              <Route path="main" element={<AdminMainCatPage />}>
                <Route path="category" element={<AdminMainCategory />} />
              </Route>
              <Route path="category" element={<AdminCategory />} />
              <Route path="sub-category" element={<AdminSubCategory />} />
              <Route path="state" element={<AdminState />} />
              <Route path="exam" element={<AdminExam />} />
              <Route path="subscription" element={<AdminSubscription />} />
              <Route path="change-password" element={<AdminChangePassword />} />
            </Route>
            <Route path="confirm-registration" element={<VeritfyEmail />} />
            <Route path="confirm-email" element={<VeritfyBlogEmail />} />
            <Route path="blog-unSubscribe" element={<BlogUnsubscription />} />
            <Route path="approved-comments" element={<VeritfyComments />} />

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>You are not authorised!</p>
                </main>
              }
            />
          </Routes>
        </Watermark>
      </SharedValueProvider>
    </div>
  );
}

export default App;
