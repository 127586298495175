import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { List, Checkbox } from "antd";
const CheckboxGroup = Checkbox.Group;

const CustomCheckbox = styled(Checkbox)`
  ${(props) =>
    props.backgroundColor &&
    css`
      & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${props.backgroundColor};
        border-color: ${props.backgroundColor};
      }
    `}
`;

function ChartComponent({
  category,
  aqdisplay,
  getMaxnumber,
  tqdisplay,
  dropvalue,
  totalCorrect,
  totalWrong
}) {
  const theme = useTheme();
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  const percentageCalc = (min, max) => {
    let finalVal = (min / max) * 100;
    return finalVal.toString();
  };

  useEffect(() => {
    setCheckedList([]);
    setCheckAll(false);
  }, [dropvalue]);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < category.length);
    setCheckAll(list.length === category.length);
  };

  useEffect(() => {
    if (checkedList.length !== 0) {
      getMaxnumber(dropvalue, checkedList);
    }
  }, [checkedList]);

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked
        ? category.map((item) => {
            return item.id;
          })
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <Box
      style={{
        border: "1.5px solid #EBECF0",
        borderRadius: "6px",
        marginTop: ".5rem"
      }}
    >
      <div
        style={{
          padding: ".5rem",
          backgroundColor:
            theme.palette.mode === "light" ? "#f1f1f1" : "#2d2e34f7"
        }}
      >
        <Typography variant="body2" fontWeight={600} marginLeft={1}>
          {"Subjects:"}
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: ".3rem"
        }}
      >
        <div
          style={{
            border: category.length === 0 ? "none" : "1px solid #d9d9d9",
            borderBottom: "none"
          }}
        >
          {category.length === 0 ? null : (
            <div
              style={{
                padding: "2px"
              }}
            >
              <Grid container>
                <Grid item xs={6} sm={6} md={7} lg={8.5}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      padding: "0 4px",
                      background: `linear-gradient(90deg,
                        ${
                          theme.palette.mode === "dark"
                            ? "green"
                            : "rgba(230,255,192,100)"
                        } ${percentageCalc(totalCorrect, tqdisplay)}%,  ${
                        theme.palette.mode === "dark"
                          ? "green"
                          : "rgba(230,255,192,100)"
                      } ${percentageCalc(totalWrong, tqdisplay)}%,
                      ${
                        theme.palette.mode === "dark"
                          ? "red"
                          : "rgba(255,235,235,100)"
                      } ${percentageCalc(totalWrong, tqdisplay)}%, ${
                        theme.palette.mode === "dark"
                          ? "red"
                          : "rgba(255,235,235,100)"
                      } ${percentageCalc(aqdisplay, tqdisplay)}%,
                        ${
                          theme.palette.mode === "dark" ? "#212529" : "#fff"
                        } ${percentageCalc(aqdisplay, tqdisplay)}%, ${
                        theme.palette.mode === "dark" ? "#212529" : "#fff"
                      } 100%
                   )`
                    }}
                  >
                    <CustomCheckbox
                      disabled={category.length === 0}
                      // style={{ backgroundColor: "#31a188" }}
                      backgroundColor="#31a188"
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      <Typography
                        variant="caption"
                        color={theme.palette.text.primary}
                      >
                        <b>All</b>
                      </Typography>
                    </CustomCheckbox>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={7}
                  lg={3.5}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <div
                    style={{
                      backgroundColor:
                        theme.palette.mode === "light"
                          ? "#f1f1f1"
                          : "#2d2e34f7",
                      height: "fit-content",
                      borderRadius: "3px"
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      variant="caption"
                      margin={"0 .5rem"}
                      color={theme.palette.text.primary}
                    >
                      Attempted {aqdisplay} of {tqdisplay}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
        <CheckboxGroup
          style={{ width: "100%" }}
          value={checkedList}
          onChange={onChange}
        >
          <List
            bordered
            style={{ width: "100%", borderRadius: "0" }}
            dataSource={category}
            renderItem={(item) => (
              <List.Item style={{ padding: "2px" }}>
                <Grid container>
                  <Grid item xs={6} sm={6} md={7} lg={8.5}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        padding: "0 4px",
                        background: `linear-gradient(90deg,
                          ${
                            theme.palette.mode === "dark"
                              ? "green"
                              : "rgba(230,255,192,100)"
                          } 0%, ${
                          theme.palette.mode === "dark"
                            ? "green"
                            : "rgba(230,255,192,100)"
                        } ${percentageCalc(item.correct, item.totalcount)}%,
                        ${
                          theme.palette.mode === "dark"
                            ? "red"
                            : "rgba(255,235,235,100)"
                        } ${percentageCalc(item.correct, item.totalcount)}%,  ${
                          theme.palette.mode === "dark"
                            ? "red"
                            : "rgba(255,235,235,100)"
                        } ${percentageCalc(item.wrong, item.totalcount)}%,
                        ${
                          theme.palette.mode === "dark" ? "#212529" : "#fff"
                        } ${percentageCalc(item.wrong, item.totalcount)}%, ${
                          theme.palette.mode === "dark" ? "#212529" : "#fff"
                        } 100%)`
                      }}
                    >
                      <CustomCheckbox backgroundColor="#31a188" value={item.id}>
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                        >
                          {item.category}{" "}
                        </Typography>
                      </CustomCheckbox>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={7}
                    lg={3.5}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <div
                      style={{
                        backgroundColor:
                          theme.palette.mode === "light"
                            ? "#f1f1f1"
                            : "#2d2e34f7",
                        height: "fit-content",
                        borderRadius: "3px"
                      }}
                    >
                      <Typography
                        fontWeight={600}
                        variant="caption"
                        margin={"0 .5rem"}
                        color={theme.palette.text.primary}
                      >
                        {item.count} of {item.totalQuestionCount}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </List.Item>
            )}
          />
        </CheckboxGroup>
      </div>
    </Box>
  );
}

export default ChartComponent;
